/* eslint-disable import/no-cycle */
<template>
  <v-container fluid>
    <v-row v-if="hasAuthorityMovementsMenu" justify="center" class="mt-3">
      <v-col cols="12" md="4">
        <v-btn color="primary" block outlined @click="redirectMovement">
          Movimentações Cadastrais
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="hasAuthorityImplantationMenu" justify="center" class="mt-3">
      <v-col cols="12" md="4">
        <v-btn color="primary" block outlined @click="redirectImplantation">
          Implantação
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="hasAuthorityDependentInclusionMenu" justify="center" class="mt-3">
      <v-col cols="12" md="4">
        <v-btn color="primary" block outlined @click="redirectDependentInclusion">
          Inclusão de dependente
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="hasAuthorityAlterationMenu" justify="center" class="mt-3">
      <v-col cols="12" md="4">
        <v-btn color="primary" block outlined @click="redirectAlteration">
          Alteração
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="hasAuthorityFamilyGroupInclusionMenu" justify="center" class="mt-3">
      <v-col cols="12" md="4">
        <v-btn color="primary" block outlined @click="redirectInclusion">
          Inclusão de Grupo Familiar
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="hasAuthorityReactivationMenu" justify="center" class="mt-3">
      <v-col cols="12" md="4">
        <v-btn color="primary" block outlined @click="redirectReactivation">
          Reativação
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="hasAuthorityExclusionMenu" justify="center" class="mt-3">
      <v-col cols="12" md="4">
        <v-btn color="primary" block outlined @click="redirectDelete">
          Exclusão
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="hasNoAuthorities" align="center" justify="center" class="mt-15">
      <v-col align="center" cols="12">
      <v-row justify="center" class="mt-3">
        <v-col cols="12" md="3" lg="3" align="center">
          <v-icon large color="primary">fas fa-low-vision</v-icon>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-3">
        <v-col cols="12" md="3" lg="3" align="center">
        <p class="text--secondary label">Você não tem permissão para visualizar os menus.</p>
        </v-col>
      </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserUtils from '@/shared/utils/user-utils';

export default {
  data: () => ({
    isRHProtegido: false,
    hasNoAuthorities: false,
    hasAuthorityMovementsMenu: false,
    hasAuthorityImplantationMenu: false,
    hasAuthorityDependentInclusionMenu: false,
    hasAuthorityAlterationMenu: false,
    hasAuthorityFamilyGroupInclusionMenu: false,
    hasAuthorityReactivationMenu: false,
    hasAuthorityExclusionMenu: false,
  }),

  created() {
    this.userUtils = new UserUtils();
  },

  async mounted() {
    if (this.$route.query && this.$route.query.isRHProtegido) {
      this.isRHProtegido = this.$route.query.isRHProtegido === 'true';
    }
    if (this.$route.query && this.$route.query.financialGroupId) {
      sessionStorage.setItem('financialGroupId', `${this.$route.query.financialGroupId}`);
    }
    this.loadAuthoritiesViewMenus();
    this.verifyAuthorities();
  },

  methods: {
    async loadAuthoritiesViewMenus() {
      this.hasAuthorityMovementsMenu = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_painel_movimentacoes_cadastrais' });
      this.hasAuthorityImplantationMenu = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_inclusao_grupo_familiar' });
      this.hasAuthorityDependentInclusionMenu = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_inclusao_dependente' });
      this.hasAuthorityAlterationMenu = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_alteracao_beneficiario' });
      this.hasAuthorityFamilyGroupInclusionMenu = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_inclusao_grupo_familiar' });
      this.hasAuthorityReactivationMenu = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_reativacao_beneficiario' });
      this.hasAuthorityExclusionMenu = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_exclusao_beneficiario' });
    },
    verifyAuthorities() {
      if (this.hasAuthorityMovementsMenu
      || this.hasAuthorityImplantationMenu
      || this.hasAuthorityDependentInclusionMenu
      || this.hasAuthorityAlterationMenu
      || this.hasAuthorityFamilyGroupInclusionMenu
      || this.hasAuthorityReactivationMenu
      || this.hasAuthorityExclusionMenu) {
        this.hasNoAuthorities = false;
      } else {
        this.hasNoAuthorities = true;
      }
    },

    redirectMovement() {
      this.$router.push(`/movement-record?isRHProtegido=${this.isRHProtegido}`);
    },

    redirectImplantation() {
      this.$router.push(`/beneficiary-plan-information?movementType=implantation&isRHProtegido=${this.isRHProtegido}`);
    },

    redirectInclusion() {
      this.$router.push(`/beneficiary-plan-information?movementType=insert&isRHProtegido=${this.isRHProtegido}`);
    },

    redirectAlteration() {
      this.$router.push(`/search-holder-dependent?isRHProtegido=${this.isRHProtegido}`);
    },

    redirectDelete() {
      this.$router.push(`/search-holder-dependent-delete?isRHProtegido=${this.isRHProtegido}`);
    },

    redirectDependentInclusion() {
      this.$router.push(`/dependent-inclusion-search-holders?isRHProtegido=${this.isRHProtegido}`);
    },

    redirectReactivation() {
      this.$router.push(`/reactivation?isRHProtegido=${this.isRHProtegido}`);
    },

    redirectMovementUpload() {
      this.$router.push(`/movement-upload?isRHProtegido=${this.isRHProtegido}`);
    },
  },
};
</script>
